<template>
  <div class="o-search" :class="inSidebar ? 'smartphone-only' : 'desktop-only'">
    <SfSearchBar
      v-model="search"
      :placeholder="$t('Type what you are looking for...')"
      class="sf-header__search"
      @input="startSearch"
      @click.native="$store.commit('ui/setSearchpanel', true)"
    >
      <SfButton
        class="sf-search-bar__button sf-button--pure"
        @click="startSearch"
        slot="icon"
        :aria-label="$t('Search')"
      >
        <span class="sf-search-bar__icon">
          <SfIcon
            v-if="inSidebar && isSearchPanelVisible"
            icon="cross"
            size="xxs"
            color="var(--c-light-gray)"
            @click.stop="closeSearchpanel"
          />
          <SfIcon
            v-if="!inSidebar"
            icon="search"
            size="xs"
            color="var(--c-light-gray)"
          />
        </span>
        <span class="sr-only">Search icon</span>
      </SfButton>
    </SfSearchBar>
    <component
      v-if="isSearchPanelVisible"
      :is="searchPanelAsyncComponent"
      :search="search"
      :page-size="size"
      :products="products"
      :read-more="readMore"
      @see-more="seeMore"
      @close="$store.commit('ui/setSearchpanel', false)"
      @reload="reloadComponent()"
    />
  </div>
</template>

<script>
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import SfSearchBar from '@storefront-ui/vue/src/components/molecules/SfSearchBar/SfSearchBar.vue';

import { mapState, mapGetters } from 'vuex';
import SearchPanelMixin from '@vue-storefront/core/compatibility/components/blocks/SearchPanel/SearchPanel';
import ALoadingSpinner from '~/theme/components/atoms/a-loading-spinner';
import ALoadingError from '~/theme/components/atoms/a-loading-error';

const SearchPanel = () =>
  import(/* webpackChunkName: "vsf-search-panel" */ '~/theme/components/organisms/o-search-panel');

export default {
  name: 'OSearch',
  components: {
    SfIcon,
    SfSearchBar,
    SfButton
  },
  props: {
    inSidebar: {
      type: Boolean
    }
  },
  data () {
    return {
      size: 16,
      timeout: null,
      searchPanelAsyncComponent: () => ({
        component: SearchPanel(),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 3000
      })
    }
  },
  mixins: [SearchPanelMixin],
  computed: {
    ...mapState({
      isSearchPanelVisible: state => state.ui.searchpanel
    }),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  methods: {
    startSearch () {
      if (this.search.length < 3) {
        return
      }

      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(async () => {
        this.makeSearch().then(() => {
          if (this.products.length) {
            this.$bus.$emit('after-product-search', this.products, this.search)
          }
        })
      }, 500)
    },
    closeSearchpanel () {
      this.search = ''
      this.$store.commit('ui/setSearchpanel', false)
    },
    reloadComponent () {
      this.searchPanelAsyncComponent = () => ({
        component: SearchPanel(),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 3000
      });
    }
  }
};
</script>
