<template>
  <div class="sf-header__language-switcher-wrapper">
    <SfMenuItem class="sf-language-switcher__item" @click.prevent="toggleLanguageSwitcher">
      <template #icon>
        <SfImage v-if="defaultFlag !== ''" :src="defaultFlag" :alt="$t('Language Flag')" :width="28" :height="20" />
      </template>
      <template #label>
        {{ country }} - {{ currency }}
      </template>
    </SfMenuItem>
  </div>
</template>

<script>
import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue'
import SfMenuItem from '@storefront-ui/vue/src/components/molecules/SfMenuItem/SfMenuItem.vue'

export default {
  name: 'ALanguageSwitcherIcon',
  components: { SfImage, SfMenuItem },
  data () {
    const storeView = currentStoreView()
    return {
      country: storeView.i18n.defaultCountryAlias || storeView.i18n.defaultCountry,
      lang: storeView.i18n.defaultLanguage,
      currency: storeView.i18n.currencyCode,
      storeCode: storeView.storeCode || config.defaultStoreCode
    }
  },
  computed: {
    storeViews () {
      return Object.keys(config.storeViews).reduce((storeViews, storeCode) => {
        if (this.isValidStoreCode(storeCode)) {
          storeViews[storeCode] = config.storeViews[storeCode];
        }
        return storeViews;
      }, {});
    },
    defaultFlag () {
      let selectedStore = this.storeViews[this.storeCode]
      return currentStoreView().flag || selectedStore.i18n.flag || ''
    }
  },
  methods: {
    toggleLanguageSwitcher () {
      this.$store.dispatch('ui/toggleLanguageSwitcher')
    },
    isValidStoreCode (storeCode) {
      const storeView = config.storeViews[storeCode];
      return !!(storeView && typeof storeView === 'object' && storeView.i18n);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.sf-header__language-switcher-wrapper {
  margin-left: var(--spacer-xs);
}

.sf-language-switcher__item {
  margin: var(--spacer-sm) 0;
  padding: 0;

  .sf-image {
    margin-right: var(--spacer-xs);
  }
}
</style>
<style lang="scss">
.sf-header__language-switcher-wrapper {
  .sf-image.has-size{
    width: 80px;
  }
}
</style>
